import {BaseCmsAdminApp} from "../cmsshared-admin/BaseCmsAdminApp";
import {CollectionsRegistry} from "../cmsshared-admin/content/types";
import {EditPostFragment, Post, PostsLoader, PostsTableFragment} from "./blog";
import {User} from "@firebase/auth";
import {EditPageFragment, Page, PagesLoader, PagesTableFragment} from "@/app/page";

export class App extends BaseCmsAdminApp {

  protected getTitle(): string {
    return "CMS Blog";
  }

  protected async onAppInitWithLoggedInUser(user: User): Promise<void> {
    await super.onAppInitWithLoggedInUser(user);
    const registry = CollectionsRegistry.getInstance();
    // registry.register({
    //   displayName: "Pages",
    //   createNew: () => Page.createNew(),
    //   clone: (page: Page) => page.clone(Page),
    //   loader: PagesLoader.getInstance(),
    //   ContentTableFragmentType: PagesTableFragment,
    //   EditContentFragmentType: EditPageFragment,
    // });
    registry.register({
      displayName: "Posts",
      createNew: () => Post.createNew(),
      clone: (post: Post) => post.clone(Post),
      loader: PostsLoader.getInstance(),
      ContentTableFragmentType: PostsTableFragment,
      EditContentFragmentType: EditPostFragment,
    });
  }
}