import React from "react";
import {HistoryEduOutlined, NotificationsOutlined, SettingsOutlined,} from "@mui/icons-material";
import {TabInfo, Tabs} from "shared/TabsContainer";
import {AppTabsContainer, AppTabsContainerProps, AppTabsContainerState} from "shared/AppTabsContainer";
import {PathComponent, PathProps} from "index";
import {Box, Button, Tooltip, Typography} from "@mui/material";
import {BORDER_RADIUS, DIVIDER, PD_SM, PD_XXSM} from "shared/dimens";
import {StyledBadge, StyledBoxRow} from "shared/StyledComponents";
import {PluginConfig} from "../shared/types";
import {THEME_OPTIONS} from "./BaseCmsAdminApp";
import {getLoginCredentials} from "../shared/BaseApp";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../shared/BaseFragment";
import {PluginConfigProvider, Plugins} from "../shared/plugins";
import {AppPrefs} from "./app_prefs";
import {white} from "../shared/colors";
import {MembersKey} from "../shared/entities";
import {ContentFragment} from "./content/ContentFragment";
import {SiteConfigProvider} from "@/shared-site/types";
import {CLOUD_STATUS_METADATA, CloudStatus} from "@/shared/constants";
import {SettingsFragment} from "@/cmsshared-admin/settings/SettingsFragment";

export enum TabType {
  CONTENT = "content",
  SETTINGS = "settings",
  PLUGINS = "plugins",
}

function createPluginConfig(podId: string): PluginConfig {
  const siteConfig = SiteConfigProvider.getInstance().get();
  return {
    app: {
      themeOptions: THEME_OPTIONS,
      firebase: siteConfig.firebaseConfig,
    },
    membersKey: MembersKey.from(podId),
    autoLogin: getLoginCredentials(),
  }
}

const TABS: Tabs<TabType> = {
  items: [
    {
      type: TabType.CONTENT,
      path: "content",
      text: "Content",
      iconType: HistoryEduOutlined,
      render: (path: PathProps) => <ContentFragment path={path}/>,
    },
    {
      type: TabType.SETTINGS,
      path: "settings",
      text: "Settings",
      iconType: SettingsOutlined,
      render: (path: PathProps) => <SettingsFragment path={path}/>,
    },
    {
      type: TabType.PLUGINS,
      path: ":plugin_id",
      groupType: "plugins",
      plugins: [],
    },
  ],
  containerId: "main",
};

type MainTabsContainerProps = AppTabsContainerProps<TabType> & {
  title: string,
}

type MainTabsContainerState = AppTabsContainerState<TabType> & {
  cloudStatus: CloudStatus,
}

class MainTabsContainer extends AppTabsContainer<TabType, MainTabsContainerProps, MainTabsContainerState> {

  protected renderToolbar(): React.ReactElement {
    const statusMetadata = CLOUD_STATUS_METADATA.find(metadata => metadata.type === this.state.cloudStatus) || CLOUD_STATUS_METADATA[1];
    return <Box style={{
      display: "flex",
      paddingLeft: PD_SM,
      paddingRight: PD_SM,
      alignItems: "center",
      position: "relative",
    }}>
      <Box style={{display: "flex"}}>
        <Box style={{display: "flex", alignItems: "center", background: white, zIndex: 1}}>
          <Typography variant="h5" style={{ paddingLeft: PD_SM, paddingRight: PD_SM, paddingTop: PD_XXSM, paddingBottom: PD_XXSM, maxWidth: 360, overflow: "hidden", whiteSpace:"nowrap", textOverflow: "ellipsis"}}>{this.props.title}</Typography>
        </Box>
      </Box>
      <StyledBoxRow style={{padding: PD_SM, alignItems: "flex-end"}}>
        <statusMetadata.iconType/>
        <Typography variant="caption">{statusMetadata.text}</Typography>
      </StyledBoxRow>
      <span style={{flexGrow: 1}}/>
      <Box style={{display: "flex", gap: PD_SM}}>
        <Button variant="contained">Build project</Button>
        <Tooltip title="Notifications">
          <StyledBadge badgeContent={1}>
            <Button onClick={() => this.props.path.navigate("/notifications")}>
              <NotificationsOutlined/>
            </Button>
          </StyledBadge>
        </Tooltip>
      </Box>
    </Box>;
  }
}

export type MainProps = BaseFragmentProps & {
  title: string,
}

type MainState = BaseFragmentState & {}

export class Main extends BaseFragment<MainProps, MainState> implements PluginConfigProvider {

  private readonly appPrefs = AppPrefs.getInstance();

  private readonly plugins = Plugins.initInstance(this.appPrefs.getPluginUrls(), this);

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  static nestedPaths(): PathComponent[] {
    return MainTabsContainer.nestedPathsFromTabs(TABS);
  }

  getPluginConfig() {
    return createPluginConfig("fixme");
  }

  protected async fetchOnMount(): Promise<void> {
    const tabInfo: TabInfo<TabType> = TABS.items.find(item => item.type === TabType.PLUGINS);
    tabInfo.plugins = await this.plugins.getOrLoadPlugins();
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <MainTabsContainer logo="/icon_w.png" path={this.props.path} tabs={TABS} title={this.props.title}/>;
  }
}
