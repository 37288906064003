import {DialogFragment, PageFragment, PageFragmentProps} from "shared/PageFragment";
import {Box} from "@mui/material";
import {FormGenContainer} from "shared/FormGenContainer";
import {DW_MD} from "shared/dimens";
import React, {ReactElement} from "react";
import {BaseContent, BaseContentLoader, Collection} from "./types";
import {App} from "../../app/App";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";

export class EditContentHelper {

  static addContent<T extends BaseContent, L extends BaseContentLoader<T>>(collection: Collection<T, L>) {
    const EditContentFragementType = collection.EditContentFragmentType;
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditContentFragementType collection={collection} content={collection.createNew()}
                                      onContentSaved={content_ => {
                                        App.CONTEXT.hideDialog();
                                        App.CONTEXT.showToast("Content saved.");
                                      }}/>);
  }

  static editContent<T extends BaseContent, L extends BaseContentLoader<T>>(collection: Collection<T, L>, content: T) {
    const EditContentFragementType = collection.EditContentFragmentType;
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditContentFragementType editing collection={collection} content={content} onContentSaved={content_ => {
        App.CONTEXT.hideDialog();
        App.CONTEXT.showToast("Content saved.");
      }}/>);
  }
}


export type BaseEditContentFragmentProps<T extends BaseContent, L extends BaseContentLoader<T>> = PageFragmentProps & {
  collection: Collection<T, L>
  editing?: boolean,
  content: T,
  onContentSaved: (content_: T) => void,
}

export type EditorOptions = {
  width?: number,
}

export abstract class BaseEditContentFragment<T extends BaseContent, L extends BaseContentLoader<T>, P extends BaseEditContentFragmentProps<T, L> = BaseEditContentFragmentProps<T, L>> extends DialogFragment<P> {

  private readonly contents = this.props.collection.loader;
  protected readonly content = this.props.collection.clone(this.props.content);

  protected onCreateState() {
    return {
      ...super.onCreateState(),
      title: this.props.editing ? "Edit" : "Add",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG;
  }

  protected getActionButtonText(): string {
    return this.props.editing ? "Save" : "Add";
  }

  onActionButtonClicked() {
    this.addContent()
      .then(() => this.props.onContentSaved(this.content));
  }

  private async addContent() {
    await this.contents.addListItem(this.content);
  }

  renderContent(): ReactElement | null {
    return this.renderEditor();
  }

  protected renderEditor(options?: EditorOptions): ReactElement {
    return <Box style={{display: "flex", width: options?.width || DW_MD}}>
      <FormGenContainer content={this.content} description={this.props.editing ? "ID: " + this.content.id : undefined}/>
    </Box>;
  }
}
