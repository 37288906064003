import {SvgIcon} from "@mui/material";
import {JsonObject} from "@/shared-site/json/json-object";
import {JsonProperty} from "@/shared-site/json/json-property";
import {dbRef_getVal, SystemKeys} from "@/shared-site/database";
import {createSiteConfig} from "@/site/config";
import {initializeApp} from "firebase/app";
import {FirebaseOptions} from "@firebase/app";
import {JSON_OBJECT, Type} from "@/shared-site/json/helpers";

export abstract class BaseKeyText<T> {

  constructor(readonly key: T, readonly text: string) {
  }
}

export class KeyText extends BaseKeyText<any> {

  constructor(key: any, text: string) {
    super(key, text);
  }
}

export class KeyTextNumber extends BaseKeyText<Number> {

  constructor(key: number, text: string) {
    super(key, text);
  }
}

export class KeyTextString extends BaseKeyText<string> {

  constructor(key: string, text: string) {
    super(key, text);
  }
}

export function $KTS(key: string, text: string) {
  return new KeyTextString(key, text);
}

export function DisplayName(firstname?: string, lastname?: string) {
  let displayName = firstname?.length > 0 ? firstname : "";
  displayName += lastname?.length > 0 ? ((displayName.length > 0 ? " " : "") + lastname) : "";
  return displayName;
}

export type EmptyConfig = {
  iconType?: typeof SvgIcon,
  iconify?: string,
  title: string,
  text?: string,
  action?: Action,
  altAction?: Action,
}

export class Action {

  tag?: any;
  destructive?: boolean;
  variant?: "text" | "contained" | "outlined";

  isSelected?: () => boolean;

  constructor(readonly text: string, readonly onClick?: (event?: any, ...args: any[]) => void, readonly iconType?: typeof SvgIcon, readonly iconUrl?: string, readonly iconify?: string, readonly disabled?: boolean) {
  }

  makeDestructive(): Action {
    this.destructive = true;
    return this;
  }
}

export class MenuOption {
  static readonly OPTIONS_ITEM_TYPE_MASK = 0x3;
  static readonly OPTIONS_ITEM_TYPE_BUTTON_FLAG = 1;
  static readonly OPTIONS_ITEM_TYPE_TEXT_FLAG = 2;
  static readonly OPTIONS_ITEM_TYPE_ACTION_FLAG = 3;

  static createFromAction(action: Action) {
    return new MenuOption(null, action.text, action.iconType, MenuOption.OPTIONS_ITEM_TYPE_BUTTON_FLAG, action.onClick);
  }

  constructor(readonly id: string, readonly text: string, readonly iconType: typeof SvgIcon = null, readonly flags: number = 0, readonly onClick?: (event) => void) {
  }
}

export type SiteSettingsObject = {
  readonly id: string,
}

export type SiteSettingsItem = {
  object: SiteSettingsObject,
  title?: string,
  text?: string,
  iconType?: typeof SvgIcon,
}

export type SiteSettings = {
  items: SiteSettingsItem[],
}

export type SiteConfig = {
  provisioningId?: string,
  provisionedAppId?: string,
  firebaseConfig?: FirebaseOptions,
  siteSettings?: SiteSettings,
}

export class SiteConfigProvider {

  private static instance: SiteConfigProvider;

  static getInstance(): SiteConfigProvider {
    if (!this.instance) {
      this.instance = new SiteConfigProvider();
    }
    return this.instance;
  }

  private readonly siteConfig: SiteConfig;

  private constructor() {
    this.siteConfig = createSiteConfig();
    if (this.siteConfig.firebaseConfig) {
      initializeApp(this.siteConfig.firebaseConfig);
    }
  }

  get(): SiteConfig {
    return this.siteConfig;
  }
}

@JsonObject()
export abstract class BaseObject {

  @JsonProperty()
  creator: string;

  @JsonProperty()
  created: number;

  constructor(creator?: string, created?: number) {
    this.creator = creator;
    this.created = created;
  }

  async onAfterObjectDeserialized(): Promise<void> {
  }
}

@JsonObject()
export abstract class TypedObject extends BaseObject {

  abstract createDefaultLoader(): DefaultObjectLoader<any>;

  private defaultLoader: DefaultObjectLoader<any>;

  private getDefaultLoader(): DefaultObjectLoader<any> {
    if (!this.defaultLoader) {
      this.defaultLoader = this.createDefaultLoader();
    }
    return this.defaultLoader;
  }

  async load(): Promise<void> {
    const object = await this.getDefaultLoader().loadObject();
    if (object) {
      Object.assign(this, object);
    }
  }

  protected abstract getType(): Type<any>;
}


export abstract class AbstractObjectLoader<T extends BaseObject> {

  protected abstract basePath(): string;

  protected abstract deserializeObject(value: any): T;

  protected abstract serializeObject(object: T): any;

  abstract loadObject(): Promise<T | null>;

  abstract getOrLoadObject(): Promise<T | null>;

  abstract getObject(): T | undefined;
}

export type ObjectConfig = {
  shared?: boolean, // Objects are not stored per member if true.
}

export abstract class BaseObjectLoader<T extends BaseObject> extends AbstractObjectLoader<T> {

  protected object: T;

  private objectLoaded: boolean = false;

  constructor(private readonly id: string, private readonly config?: ObjectConfig) {
    super();
  }

  protected basePath(): string {
    return this.id;
  }

  protected abstract deserializeObject(value: any): T;

  protected async onAfterObjectDeserialized(object: T): Promise<T> {
    await object.onAfterObjectDeserialized();
    return object;
  }

  protected abstract serializeObject(object: T): any;

  private listPath(): string {
    const basePath = this.basePath();
    let contextScope: string = "";
    const provisionedAppId = SiteConfigProvider.getInstance().get().provisionedAppId;
    if (provisionedAppId) {
      contextScope = "apps/" + provisionedAppId + "/";
    }
    return contextScope + basePath;
  }

  protected onStartObserving() {
    // TODO: Implement this.
  }

  async loadObject(): Promise<T | null> {
    const val = await dbRef_getVal(this.listPath());
    if (val) {
      return await this.loadObjectInternal(val);
    }
    return Promise.resolve(null);
  }

  private async loadObjectInternal(val) {
    let object = this.deserializeObject(val);
    object = await this.onAfterObjectDeserialized(object);
    this.objectLoaded = true;
    return object;
  }

  async getOrLoadObject(): Promise<T | null> {
    if (!this.objectLoaded) {
      this.object = await this.loadObject();
    }
    return Promise.resolve(this.getObject());
  }

  getObject(): T | null {
    return this.object;
  }
}

export class DefaultObjectLoader<T extends BaseObject> extends BaseObjectLoader<T> {

  constructor(id: string, private readonly type: Type<T>, config?: ObjectConfig) {
    super(id, config);
  }

  protected deserializeObject(value: any): T {
    return JSON_OBJECT.deserializeObject(value, this.type);
  }

  protected serializeObject(object: T): any {
    return JSON_OBJECT.serializeObject(object);
  }
}

@JsonObject()
export abstract class BaseListItem {

  @JsonProperty()
  id: string;

  @JsonProperty()
  creator: string;

  @JsonProperty()
  created: number;

  protected constructor(id: string, creator?: string, created?: number) {
    this.id = id;
    this.creator = creator;
    this.created = created;
  }

  async onAfterItemDeserialized(): Promise<void> {
  }
}

export abstract class AbstractListItemsLoader<T extends BaseListItem> {

  protected abstract basePath(): string;

  protected abstract deserializeItem(value: any): T;

  protected abstract sortOrder(item1: T, item2: T): number;

  abstract loadListItems(): Promise<void>;

  abstract getOrLoadListItems(): Promise<T[]>;

  abstract getListItems(): T[];
}

export abstract class BaseListItemsLoader<T extends BaseListItem> extends AbstractListItemsLoader<T> {

  private readonly items = new Map<string, T>();
  private sortedItems: T[];

  private itemsLoaded: boolean = false;

  protected abstract basePath(): string;

  protected abstract deserializeItem(value: any): T;

  protected async onAfterItemDeserialized(item: T): Promise<T> {
    await item.onAfterItemDeserialized();
    return item;
  }

  protected abstract sortOrder(item1: T, item2: T): number;

  private listPath(): string {
    const basePath = this.basePath();
    let contextScope: string = "";
    const provisionedAppId = SiteConfigProvider.getInstance().get().provisionedAppId;
    if (provisionedAppId) {
      contextScope = "apps/" + provisionedAppId + "/";
    }
    return contextScope + basePath;
  }

  async loadListItems(): Promise<void> {
    const path = this.listPath();
    const val = await dbRef_getVal(path);
    if (val) {
      const awaitAll: Promise<T>[] = [];
      for (const key in val) {
        let value = val[key];
        let item = this.deserializeItem(value);
        awaitAll.push(this.onAfterItemDeserialized(item));
      }
      const items = await Promise.all(awaitAll);
      for (const item of items) {
        this.items.set(item.id, item);
      }
    }
    this.itemsLoaded = true;
    return Promise.resolve();
  }

  async loadListItem(id: string): Promise<T | null> {
    const val = await dbRef_getVal(this.listPath() + "/" + id);
    if (val) {
      return await this.loadListItemInternal(val);
    }
    return Promise.resolve(null);
  }

  private async loadListItemInternal(val) {
    let item = this.deserializeItem(val);
    item = await this.onAfterItemDeserialized(item);
    return item;
  }

  async getOrLoadListItems(): Promise<T[]> {
    if (!this.sortedItems) {
      await this.loadListItems();
    }
    return Promise.resolve(this.getListItems());
  }

  getListItems(): T[] {
    if (!this.itemsLoaded) {
      console.error("Getting items but they're not loaded yet.");
    }
    if (!this.sortedItems) {
      this.sortedItems = [...this.items.values()].sort(this.sortOrder);
    }
    return this.sortedItems;
  }

  getListItem(id: string): T | undefined {
    if (!id) {
      return undefined;
    }
    // Don't use getListItems() here as that will instantiate sortedItems which we want only when loading entire list.
    return [...this.items.values()].find(value => value.id === id);
  }

  async getOrLoadItem(id: string): Promise<T | null> {
    const item = this.getListItem(id);
    if (item) {
      return item;
    }
    return this.loadListItem(id);
  }
}

@JsonObject()
export abstract class BaseTreeItem extends BaseListItem {

  static ROOT_ID = "__root__";

  @JsonProperty()
  parentId: string;

  protected constructor(id: string, creator: string, created: number, parentId: string) {
    super(id, creator, created);
    this.parentId = parentId || BaseTreeItem.ROOT_ID;
  }
}

export abstract class BaseTreeItemsLoader<T extends BaseTreeItem> {

  private readonly itemsMap = new Map<string, Map<string, T>>();
  private sortedItemsMap = new Map<string, T[]>();

  abstract basePath(): string;

  relName(): string {
    return "children";
  }

  abstract deserializeItem(value: any): T;

  protected async onAfterItemDeserialized(item: T): Promise<T> {
    return item;
  }

  protected async onBeforeItemSerialized(item: T): Promise<T> {
    return item;
  }

  abstract serializeItem(item: T): any;

  abstract sortOrder(item1: T, item2: T): number;

  private childrenPath(id: string): string {
    const childrenPath = this.basePath() + "/" + SystemKeys.REL + "/" + this.relName() + "/" + id + "/";
    let contextScope: string = "";
    const provisionedAppId = SiteConfigProvider.getInstance().get().provisionedAppId;
    if (provisionedAppId) {
      contextScope = "apps/" + provisionedAppId + "/";
    }
    return contextScope + childrenPath;
  }

  private listPath(): string {
    const basePath = this.basePath();
    let contextScope: string = "";
    const provisionedAppId = SiteConfigProvider.getInstance().get().provisionedAppId;
    if (provisionedAppId) {
      contextScope = "apps/" + provisionedAppId + "/";
    }
    return contextScope + basePath;
  }

  private getItemsMap(id: string): Map<string, T> {
    let map = this.itemsMap.get(id);
    if (!map) {
      map = new Map<string, T>();
      this.itemsMap.set(id, map);
    }
    return map;
  }

  async loadTreeItems(id: string): Promise<void> {
    const path = this.childrenPath(id);
    const children = await dbRef_getVal(path);
    if (children) {
      for (const key in children) {
        const child = children[key];
        const value = await dbRef_getVal(this.listPath() + "/" + child);
        if (value) {
          let item = this.deserializeItem(value);
          item = await this.onAfterItemDeserialized(item);
          this.getItemsMap(id).set(item.id, item);
        }
      }
    }
    return Promise.resolve();
  }

  private async loadListItemInternal(val) {
    let item = this.deserializeItem(val);
    item = await this.onAfterItemDeserialized(item);
    return item;
  }

  async getOrLoadTreeItems(id: string): Promise<T[]> {
    if (!this.sortedItemsMap.get(id)) {
      await this.loadTreeItems(id);
    }
    return Promise.resolve(this.getTreeItems(id));
  }

  getTreeItems(id: string): T[] {
    if (!this.sortedItemsMap.get(id)) {
      this.sortedItemsMap.set(id, [...this.getItemsMap(id)?.values()].sort(this.sortOrder));
    }
    return this.sortedItemsMap.get(id);
  }

  getTreeItem(id: string, itemId: string): T | undefined {
    return this.sortedItemsMap.get(id)?.find(value => value.id === itemId);
  }

  async getOrLoadTreeItem(id: string, itemId: string): Promise<T | undefined> {
    // TODO: Implement this.
    return undefined;
  }
}

export class DefaultTreeListItemsLoader<T extends BaseTreeItem> extends AbstractListItemsLoader<T> {

  constructor(private readonly id: string, private readonly loader: BaseTreeItemsLoader<T>) {
    super();
  }

  protected basePath(): string {
    return this.loader.basePath();
  }

  protected deserializeItem(value: any): T {
    return this.loader.deserializeItem(value);
  }

  protected serializeItem(item: T): any {
    return this.loader.serializeItem(item);
  }

  protected sortOrder(item1: T, item2: T): number {
    return this.loader.sortOrder(item1, item2);
  }

  async loadListItems(): Promise<void> {
    return this.loader.loadTreeItems(this.id);
  }

  async getOrLoadListItems(): Promise<T[]> {
    return this.loader.getOrLoadTreeItems(this.id);
  }

  getListItems(): T[] {
    return this.loader.getTreeItems(this.id);
  }

  getListItem(id: string): T | undefined {
    return this.loader.getTreeItem(this.id, id);
  }

  async getOrLoadItem(id: string): Promise<T | null> {
    return this.loader.getOrLoadTreeItem(this.id, id);
  }
}
