import {
  TableDataSourceFragment,
  TableDataSourceFragmentProps,
  TableDataSourceFragmentState
} from "../../shared/TableDataSourceFragment";
import {TableDataContainer, TableDataRow} from "../../shared/TableDataContainer";
import {TableData} from "../../shared/tabledata";
import {BaseContent, BaseContentLoader, Collection} from "./types";
import {ReactElement} from "react";
import {OnListItemsListener, UserDisplayName, UserProfilePhoto} from "../../shared/types";
import {DateUtil} from "../../shared/date_util";
import {EditContentHelper} from "@/cmsshared-admin/content/BaseEditContentFragment";

export class BaseContentTableDataRow implements TableDataRow {

  @TableData({name: "ID", cellStyle: {maxWidth: 48}})
  id: string;
}

export class BaseContentTableDataRowEx extends BaseContentTableDataRow {

  @TableData({name: "", type: "profile_photo"})
  profilePhoto: string;

  @TableData({name: "Creator"})
  creator: string;

  @TableData({name: "Created"})
  created: string;
}

export type BaseContentTableFragmentProps<T extends BaseContent, L extends BaseContentLoader<T>> =
  TableDataSourceFragmentProps & {
  collection: Collection<T, L>,
  filter?: (content: T) => boolean,
}

export abstract class BaseContentTableFragment<T extends BaseContent, L extends BaseContentLoader<T>, R extends BaseContentTableDataRow> extends TableDataSourceFragment<T, R, BaseContentTableFragmentProps<T, L>> implements OnListItemsListener<T> {

  protected onCreateState(): TableDataSourceFragmentState {
    return {
      ...super.onCreateState(),
      title: this.props.collection.displayName,
    };
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await this.props.collection.loader.loadListItems();
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.collection.loader.registerObserver(this);
  }

  componentWillUnmount() {
    this.props.collection.loader.unregisterObserver(this);
  }

  onItemChanged(item: T) {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps: Readonly<BaseContentTableFragmentProps<T, L>>, prevState: Readonly<TableDataSourceFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (this.props.collection !== prevProps.collection) {
      this.setState({
        title: this.props.collection.displayName,
      });
    }
  }

  applyTableDataToRow(data: T, row: R): void {
    row.id = data.id;
    if (row instanceof BaseContentTableDataRowEx) {
      const rowEx = row as BaseContentTableDataRowEx;
      rowEx.profilePhoto = UserProfilePhoto(data.member.user);
      rowEx.creator = UserDisplayName(data.member.user);
      rowEx.created = DateUtil.formatDateTime(data.created);
    }
  }

  abstract createTableDataRow(): R;

  onTableCellChanged(data: T, cellId: string, value: any): void {
  }

  onTableDataSelected(data: T) {
    EditContentHelper.editContent(this.props.collection, data);
  }

  renderContent(): ReactElement {
    let items = this.props.collection.loader.getListItems();
    if (this.props.filter) {
      items = items.filter(this.props.filter);
    }
    return <TableDataContainer data={items} source={this}/>
  }
}