import {get} from "@firebase/database";
import {SvgIcon} from "@mui/material";
import {BaseApp, Context, ContextType} from "./BaseApp";
import {Member, Members, MembersKey, User} from "./entities";
import {
  dbRef,
  dbRef_getVal,
  dbRef_onChildAdded,
  dbRef_removeVal,
  dbRef_setVal,
  GLOBAL_PATHS,
  ProvisioningContext,
  Rel,
  SystemKeys
} from "./database";
import {deleteObject, getStorage, ref,} from "firebase/storage";
import {JsonObject} from "./json/json-object";
import {JsonProperty} from "./json/json-property";
import {findIcon} from "./icons";
import {getMemberAuth} from "./auth";
import {JSON_OBJECT, Type} from "./json/helpers";
import {CloudStatus} from "./constants";
import {ReactElement} from "react";
import {PluginApp} from "./PluginApp";
import {ProvisionedApp} from "./ProvisionedApp";

export abstract class BaseKeyText<T> {

  constructor(readonly key: T, readonly text: string) {
  }
}

export class KeyText extends BaseKeyText<any> {

  constructor(key: any, text: string) {
    super(key, text);
  }
}

export class KeyTextNumber extends BaseKeyText<Number> {

  constructor(key: number, text: string) {
    super(key, text);
  }
}

export class KeyTextString extends BaseKeyText<string> {

  constructor(key: string, text: string) {
    super(key, text);
  }
}

export function $KTS(key: string, text: string) {
  return new KeyTextString(key, text);
}

export type FileUploadOptions = {}

export abstract class Observable<T> {

  protected observers: T[] = [];

  registerObserver(observer: T) {
    const wasEmpty = this.observers.length === 0;
    if (!this.observers.includes(observer)) {
      this.observers.push(observer);
    }
    if (wasEmpty) {
      this.onStartObserving();
    }
  }

  protected onStartObserving() {
  }

  unregisterObserver(observer: T) {
    this.observers = this.observers.filter(value => value !== observer);
    if (this.observers.length === 0) {
      this.onStopObserving();
    }
  }

  protected onStopObserving() {
  }
}

export abstract class Observable1<T> {

  protected readonly observersMap = new Map<string, T[]>();

  registerObserver(id: string, observer: T) {
    let observers = this.getObservers(id);
    const wasEmpty = observers.length === 0;
    if (!observers.includes(observer)) {
      observers.push(observer);
    }
    if (wasEmpty) {
      this.onStartObserving(id);
    }
  }

  getObservers(id: string) {
    let observers = this.observersMap.get(id);
    if (!observers) {
      observers = [];
      this.observersMap.set(id, observers);
    }
    return observers;
  }

  protected onStartObserving(id: string) {
  }

  unregisterObserver(id: string, observer: T) {
    let observers = this.observersMap.get(id);
    if (!observers) {
      return;
    }
    observers = observers.filter(value => value !== observer);
    this.observersMap.set(id, observers);
    if (observers.length === 0) {
      this.onStopObserving(id);
    }
  }

  protected onStopObserving(id: string) {
  }
}

@JsonObject()
export abstract class BaseObject {

  member: Member;

  @JsonProperty()
  creator: string;

  @JsonProperty()
  created: number;

  protected constructor(creator?: string, created?: number) {
    this.creator = creator;
    this.created = created;
  }

  // Return associated files that are deleted when this object is deleted.
  protected getFiles(): string[] {
    return undefined;
  }

  async onAfterObjectDeserialized(): Promise<void> {
  }

  async onBeforeObjectSerialized(): Promise<void> {
  }

  async onBeforeObjectDeleted(): Promise<void> {
    await Promise.all(this.getFiles()?.map(fileUrl => {
      if (!fileUrl) {
        return;
      }
      return deleteObject(ref(getStorage(), fileUrl));
    }));
  }

  clone<T extends BaseObject>(dataType: Type<T>): T {
    return JSON_OBJECT.deserializeObject(JSON_OBJECT.serializeObject(this), dataType);
  }
}

@JsonObject()
export abstract class TypedObject extends BaseObject {

  abstract createDefaultLoader(): DefaultObjectLoader<any>;

  private defaultLoader: DefaultObjectLoader<any>;

  private getDefaultLoader(): DefaultObjectLoader<any> {
    if (!this.defaultLoader) {
      this.defaultLoader = this.createDefaultLoader();
    }
    return this.defaultLoader;
  }

  async load(): Promise<void> {
    const object = await this.getDefaultLoader().loadObject();
    if (object) {
      Object.assign(this, object);
    }
  }

  async save(): Promise<void> {
    await this.getDefaultLoader().setObject(this);
  }

  protected abstract getType(): Type<any>;
}


export abstract class AbstractObjectLoader<T extends BaseObject> extends Observable<OnObjectListener<T>> {

  protected abstract basePath(): string;

  protected abstract deserializeObject(value: any): T;

  protected abstract serializeObject(object: T): any;

  abstract loadObject(): Promise<T | null>;

  abstract getOrLoadObject(): Promise<T | null>;

  abstract getObject(): T | undefined;

  abstract setObject(object: T, rel?: Rel): Promise<void>;

  abstract deleteObject(object: T): Promise<void>;
}

export enum ObjectChange {
  ADDED,
  CHANGED,
  REMOVED,
}

export interface OnObjectListener<T extends BaseObject> {

  onObjectChanged(object: T, change: ObjectChange);
}

export type ObjectConfig = {
  shared?: boolean, // Objects are not stored per member if true.
  overrideProvisioningContext?: ProvisioningContext,
}

export abstract class BaseObjectLoader<T extends BaseObject> extends AbstractObjectLoader<T> {

  private readonly memberAuth = getMemberAuth();

  protected object: T;

  private objectLoaded: boolean = false;

  constructor(private readonly id: string, private readonly config?: ObjectConfig) {
    super();
  }

  private getProvisioningContext(): ProvisioningContext {
    return this.config?.overrideProvisioningContext || ProvisioningContext.DEFAULT;
  }

  protected basePath(): string {
    return this.id;
  }

  protected abstract deserializeObject(value: any): T;

  protected async onAfterObjectDeserialized(object: T): Promise<T> {
    await object.onAfterObjectDeserialized();
    return object;
  }

  protected async onBeforeObjectSerialized(object: T): Promise<T> {
    await object.onBeforeObjectSerialized();
    return object;
  }

  protected abstract serializeObject(object: T): any;

  private listPath(): string {
    const basePath = this.basePath();
    if (GLOBAL_PATHS.includes(basePath)) {
      return basePath;
    }
    let contextScope: string = "";
    if (BaseApp.CONTEXT.contextType() === ContextType.PLUGIN) {
      contextScope = "plugins/" + (BaseApp.CONTEXT as PluginApp).getPluginId() + "/";
    } else if (BaseApp.CONTEXT.contextType() === ContextType.PROVISIONED_APP) {
      contextScope = "apps/" + (BaseApp.CONTEXT as ProvisionedApp).getProvisionedAppId() + "/";
    }
    const memberScope = this.config?.shared ? "" : ("/" + this.memberAuth.member.memberId);
    return contextScope + basePath + this.memberAuth.member.key.path() + memberScope;
  }

  protected onStartObserving() {
    // TODO: Implement this.
  }

  async loadObject(): Promise<T | null> {
    const val = await this.getProvisioningContext().dbRef_getVal(this.listPath());
    if (val) {
      return await this.loadObjectInternal(val);
    }
    return Promise.resolve(null);
  }

  private async loadObjectInternal(val) {
    let object = this.deserializeObject(val);
    if (object.creator) {
      object.member = await Members.getInstance().getOrLoadMember(object.creator);
    }
    object = await this.onAfterObjectDeserialized(object);
    this.objectLoaded = true;
    return object;
  }

  async getOrLoadObject(): Promise<T | null> {
    if (!this.objectLoaded) {
      this.object = await this.loadObject();
    }
    return Promise.resolve(this.getObject());
  }

  getObject(): T | null {
    return this.object;
  }

  async setObject(obj: T, rel?: Rel): Promise<void> {
    BaseApp.CONTEXT.notifyAppEvent("cloud_status", CloudStatus.SYNCING);
    obj = await this.onBeforeObjectSerialized(obj);
    const item = this.serializeObject(obj);
    // console.log("pr: " + this.getProvisioningContext()?.overrideProvisioningId);
    await this.getProvisioningContext().dbRef_setVal(this.listPath(), item, rel);
    BaseApp.CONTEXT.notifyAppEvent("cloud_status", CloudStatus.SYNCED);
  }

  protected async onBeforeObjectDeleted(object: T): Promise<void> {
    await object.onBeforeObjectDeleted();
  }

  async deleteObject(object: T): Promise<void> {
    await this.onBeforeObjectDeleted(object);
    await this.getProvisioningContext().dbRef_removeVal(this.listPath());
  }
}

export class DefaultObjectLoader<T extends BaseObject> extends BaseObjectLoader<T> {

  constructor(id: string, private readonly type: Type<T>, config?: ObjectConfig) {
    super(id, config);
  }

  protected deserializeObject(value: any): T {
    return JSON_OBJECT.deserializeObject(value, this.type);
  }

  protected serializeObject(object: T): any {
    return JSON_OBJECT.serializeObject(object);
  }
}

@JsonObject()
export abstract class BaseListItem {

  member: Member;

  @JsonProperty()
  id: string;

  @JsonProperty()
  creator: string;

  @JsonProperty()
  created: number;

  protected constructor(id: string, creator?: string, created?: number) {
    this.id = id;
    this.creator = creator;
    this.created = created;
  }

  // Return associated files that are deleted when this item is deleted.
  protected getFiles(): string[] {
    return undefined;
  }

  async onAfterItemDeserialized(): Promise<void> {
  }

  async onBeforeItemSerialized(): Promise<void> {
  }

  async onBeforeItemDeleted(): Promise<void> {
    await Promise.all(this.getFiles()?.map(fileUrl => {
      if (!fileUrl) {
        return;
      }
      return deleteObject(ref(getStorage(), fileUrl));
    }));
  }

  clone<T extends BaseListItem>(dataType: Type<T>): T {
    return JSON_OBJECT.deserializeObject(JSON_OBJECT.serializeObject(this), dataType);
  }
}

export abstract class AbstractListItemsLoader<T extends BaseListItem> extends Observable<OnListItemsListener<T>> {

  protected abstract basePath(): string;

  protected abstract deserializeItem(value: any): T;

  protected abstract serializeItem(item: T): any;

  protected abstract sortOrder(item1: T, item2: T): number;

  abstract loadListItems(): Promise<void>;

  abstract getOrLoadListItems(): Promise<T[]>;

  abstract getListItems(): T[];

  abstract getListItem(id: string): T | undefined;

  abstract getOrLoadItem(id: string): Promise<T | null>;

  abstract addListItem(item: T, rel?: Rel): Promise<void>;

  abstract deleteListItem(item: T): Promise<void>;
}

export enum ListItemChange {
  ADDED,
  CHANGED,
  REMOVED,
}

export interface OnListItemsListener<T extends BaseListItem> {

  onItemChanged(item: T, change: ListItemChange);
}

export type ListConfig = {
  shared?: boolean, // Items are not stored per member if true.
  overrideProvisioningContext?: ProvisioningContext,
}

export abstract class BaseListItemsLoader<T extends BaseListItem> extends AbstractListItemsLoader<T> {

  private readonly memberAuth = getMemberAuth();

  private readonly items = new Map<string, T>();
  private sortedItems: T[];

  private itemsLoaded: boolean = false;

  constructor(private readonly config?: ListConfig) {
    super();
  }

  private getProvisioningContext(): ProvisioningContext {
    return this.config?.overrideProvisioningContext || ProvisioningContext.DEFAULT;
  }

  protected abstract basePath(): string;

  protected abstract deserializeItem(value: any): T;

  protected async onAfterItemDeserialized(item: T): Promise<T> {
    await item.onAfterItemDeserialized();
    return item;
  }

  protected async onBeforeItemSerialized(item: T): Promise<T> {
    await item.onBeforeItemSerialized();
    return item;
  }

  protected abstract serializeItem(item: T): any;

  protected abstract sortOrder(item1: T, item2: T): number;

  private listPath(): string {
    const basePath = this.basePath();
    if (GLOBAL_PATHS.includes(basePath)) {
      return basePath;
    }
    let contextScope: string = "";
    if (BaseApp.CONTEXT.contextType() === ContextType.PLUGIN) {
      contextScope = "plugins/" + (BaseApp.CONTEXT as PluginApp).getPluginId() + "/";
    } else if (BaseApp.CONTEXT.contextType() === ContextType.PROVISIONED_APP) {
      contextScope = "apps/" + (BaseApp.CONTEXT as ProvisionedApp).getProvisionedAppId() + "/";
    }
    const memberScope = this.config?.shared ? "" : ("/" + this.memberAuth.member.memberId);
    return contextScope + basePath + this.memberAuth.member.key.path() + memberScope;
  }

  async loadListItems(): Promise<void> {
    const path = this.listPath();
    const val = await this.getProvisioningContext().dbRef_getVal(path);
    if (val) {
      const awaitAll: Promise<T>[] = [];
      for (const key in val) {
        let value = val[key];
        let item = this.deserializeItem(value);
        item.member = item.creator && await Members.getInstance().getOrLoadMember(item.creator);
        awaitAll.push(this.onAfterItemDeserialized(item));
      }
      const items = await Promise.all(awaitAll);
      for (const item of items) {
        this.items.set(item.id, item);
      }
    }
    this.itemsLoaded = true;
    return Promise.resolve();
  }

  protected onStartObserving() {
    const path = this.listPath();
    this.getProvisioningContext().dbRef_onChildAdded(path, (result) => {
      const value = result.val();
      if (value) {
        this.loadListItemInternal(value).then(item => {
          this.sortedItems = null;
          this.items.set(item.id, item);
          this.observers.forEach(observer => observer.onItemChanged(item, ListItemChange.ADDED));
        });
      }
    });
    this.getProvisioningContext().dbRef_onChildChanged(path, (result) => {
      const value = result.val();
      if (value) {
        this.loadListItemInternal(value).then(item => {
          this.sortedItems = null;
          this.items.set(item.id, item);
          this.observers.forEach(observer => observer.onItemChanged(item, ListItemChange.CHANGED));
        });
      }
    });
    this.getProvisioningContext().dbRef_onChildRemoved(path, (result) => {
      const value = result.val();
      if (value) {
        this.loadListItemInternal(value).then(item => {
          this.sortedItems = null;
          this.items.delete(item.id);
          this.observers.forEach(observer => observer.onItemChanged(item, ListItemChange.REMOVED));
        });
      }
    });
  }

  async loadListItem(id: string): Promise<T | null> {
    const val = await this.getProvisioningContext().dbRef_getVal(this.listPath() + "/" + id);
    if (val) {
      return await this.loadListItemInternal(val);
    }
    return Promise.resolve(null);
  }

  private async loadListItemInternal(val) {
    let item = this.deserializeItem(val);
    if (item.creator) {
      item.member = await Members.getInstance().getOrLoadMember(item.creator);
    }
    item = await this.onAfterItemDeserialized(item);
    return item;
  }

  async getOrLoadListItems(): Promise<T[]> {
    if (!this.sortedItems) {
      await this.loadListItems();
    }
    return Promise.resolve(this.getListItems());
  }

  getListItems(): T[] {
    if (!this.itemsLoaded) {
      console.error("Getting items but they're not loaded yet.");
    }
    if (!this.sortedItems) {
      this.sortedItems = [...this.items.values()].sort(this.sortOrder);
    }
    return this.sortedItems;
  }

  getListItem(id: string): T | undefined {
    if (!id) {
      return undefined;
    }
    // Don't use getListItems() here as that will instantiate sortedItems which we want only when loading entire list.
    return [...this.items.values()].find(value => value.id === id);
  }

  async getOrLoadItem(id: string): Promise<T | null> {
    const item = this.getListItem(id);
    if (item) {
      return item;
    }
    return this.loadListItem(id);
  }

  async addChildItem(item: T, childPath: string, childItem: any): Promise<void> {
    const object = JSON_OBJECT.serializeObject(childItem);
    return this.getProvisioningContext().dbRef_setVal(this.listPath() + "/" + item.id + "/" + childPath, object);
  }

  async addListItem(item: T, rel?: Rel): Promise<void> {
    BaseApp.CONTEXT.notifyAppEvent("cloud_status", CloudStatus.SYNCING);
    item = await this.onBeforeItemSerialized(item);
    const object = this.serializeItem(item);
    // console.log("pr: " + this.getProvisioningContext()?.overrideProvisioningId);
    await this.getProvisioningContext().dbRef_setVal(this.listPath() + "/" + item.id, object, rel);
    BaseApp.CONTEXT.notifyAppEvent("cloud_status", CloudStatus.SYNCED);
  }

  protected async onBeforeItemDeleted(item: T): Promise<void> {
    await item.onBeforeItemDeleted();
  }

  async deleteListItem(item: T): Promise<void> {
    await this.onBeforeItemDeleted(item);
    await this.deleteListItemById(item.id);
  }

  async deleteListItemById(id: string): Promise<void> {
    await this.getProvisioningContext().dbRef_removeVal(this.listPath() + "/" + id);
  }
}

@JsonObject()
export abstract class BaseTreeItem extends BaseListItem {

  static ROOT_ID = "__root__";

  @JsonProperty()
  parentId: string;

  protected constructor(id: string, creator: string, created: number, parentId: string) {
    super(id, creator, created);
    this.parentId = parentId || BaseTreeItem.ROOT_ID;
  }
}

export interface OnTreeItemsListener<T extends BaseTreeItem> {

  onItemChanged(item: T);
}

export abstract class BaseTreeItemsLoader<T extends BaseTreeItem> extends Observable1<OnTreeItemsListener<T>> {

  private readonly memberAuth = getMemberAuth();

  private readonly itemsMap = new Map<string, Map<string, T>>();
  private sortedItemsMap = new Map<string, T[]>();

  abstract basePath(): string;

  relName(): string {
    return "children";
  }

  abstract deserializeItem(value: any): T;

  protected async onAfterItemDeserialized(item: T): Promise<T> {
    return item;
  }

  protected async onBeforeItemSerialized(item: T): Promise<T> {
    return item;
  }

  abstract serializeItem(item: T): any;

  abstract sortOrder(item1: T, item2: T): number;

  private childrenPath(id: string): string {
    return this.basePath() + this.memberAuth.member.key.path() + "/" + this.memberAuth.member.memberId + "/" + SystemKeys.REL + "/" + this.relName() + "/" + id + "/";
  }

  private listPath(): string {
    return this.basePath() + this.memberAuth.member.key.path() + "/" + this.memberAuth.member.memberId;
  }

  private getItemsMap(id: string): Map<string, T> {
    let map = this.itemsMap.get(id);
    if (!map) {
      map = new Map<string, T>();
      this.itemsMap.set(id, map);
    }
    return map;
  }

  async loadTreeItems(id: string): Promise<void> {
    const path = this.childrenPath(id);
    const children = await dbRef_getVal(path);
    if (children) {
      for (const key in children) {
        const child = children[key];
        const value = await dbRef_getVal(this.listPath() + "/" + child);
        if (value) {
          let item = this.deserializeItem(value);
          item.member = await Members.getInstance().getOrLoadMember(item.creator);
          item = await this.onAfterItemDeserialized(item);
          this.getItemsMap(id).set(item.id, item);
        }
      }
    }
    return Promise.resolve();
  }

  protected onStartObserving(id: string) {
    const path = this.childrenPath(id);
    dbRef_onChildAdded(path, (result) => {
      const child = result.val();
      if (child) {
        dbRef_getVal(this.listPath() + "/" + child).then(value => {
          if (value) {
            this.loadListItemInternal(value).then(item => {
              this.sortedItemsMap.set(id, null);
              this.getItemsMap(id).set(item.id, item);
              this.getObservers(id).forEach(observer => observer.onItemChanged(item));
            });
          }
        });
      }
    });
  }

  private async loadListItemInternal(val) {
    let item = this.deserializeItem(val);
    item.member = await Members.getInstance().getOrLoadMember(item.creator);
    item = await this.onAfterItemDeserialized(item);
    return item;
  }

  async getOrLoadTreeItems(id: string): Promise<T[]> {
    if (!this.sortedItemsMap.get(id)) {
      await this.loadTreeItems(id);
    }
    return Promise.resolve(this.getTreeItems(id));
  }

  getTreeItems(id: string): T[] {
    if (!this.sortedItemsMap.get(id)) {
      this.sortedItemsMap.set(id, [...this.getItemsMap(id)?.values()].sort(this.sortOrder));
    }
    return this.sortedItemsMap.get(id);
  }

  getTreeItem(id: string, itemId: string): T | undefined {
    return this.sortedItemsMap.get(id)?.find(value => value.id === itemId);
  }

  async getOrLoadTreeItem(id: string, itemId: string): Promise<T | undefined> {
    // TODO: Implement this.
    return undefined;
  }

  async addTreeItem(item: T): Promise<void> {
    item = await this.onBeforeItemSerialized(item);
    const object = this.serializeItem(item);
    return dbRef_setVal(this.listPath() + "/" + item.id, object, new Rel(this.relName(), item.parentId, item.id, true));
  }

  async deleteTreeItem(item: T): Promise<void> {
    return dbRef_removeVal(this.listPath() + "/" + item.id);
  }
}

export class DefaultTreeListItemsLoader<T extends BaseTreeItem> extends AbstractListItemsLoader<T> {

  constructor(private readonly id: string, private readonly loader: BaseTreeItemsLoader<T>) {
    super();
  }

  protected basePath(): string {
    return this.loader.basePath();
  }

  protected deserializeItem(value: any): T {
    return this.loader.deserializeItem(value);
  }

  protected serializeItem(item: T): any {
    return this.loader.serializeItem(item);
  }

  protected sortOrder(item1: T, item2: T): number {
    return this.loader.sortOrder(item1, item2);
  }

  async loadListItems(): Promise<void> {
    return this.loader.loadTreeItems(this.id);
  }

  async getOrLoadListItems(): Promise<T[]> {
    return this.loader.getOrLoadTreeItems(this.id);
  }

  getListItems(): T[] {
    return this.loader.getTreeItems(this.id);
  }

  getListItem(id: string): T | undefined {
    return this.loader.getTreeItem(this.id, id);
  }

  async getOrLoadItem(id: string): Promise<T | null> {
    return this.loader.getOrLoadTreeItem(this.id, id);
  }

  async addListItem(item: T): Promise<void> {
    return this.loader.addTreeItem(item);
  }

  async deleteListItem(item: T): Promise<void> {
    return this.loader.deleteTreeItem(item);
  }
}

export class MenuOption {
  static readonly OPTIONS_ITEM_TYPE_MASK = 0x3;
  static readonly OPTIONS_ITEM_TYPE_BUTTON_FLAG = 1;
  static readonly OPTIONS_ITEM_TYPE_TEXT_FLAG = 2;
  static readonly OPTIONS_ITEM_TYPE_ACTION_FLAG = 3;

  static createFromAction(action: Action) {
    return new MenuOption(null, action.text, action.iconType, MenuOption.OPTIONS_ITEM_TYPE_BUTTON_FLAG, action.onClick);
  }

  constructor(readonly id: string, readonly text: string, readonly iconType: typeof SvgIcon = null, readonly flags: number = 0, readonly onClick?: (event) => void) {
  }
}

export type Sync = {
  syncId: string,
  type: string,
  from?: string,
  time: number,
  duration?: number,
  payload?: any,
}

export interface JSONType {

  toJSON(): any;
}

export interface StringType {

  toString(): string;
}

export interface Copyable<T> {
  copy(): T;
}

export class LocalFile {

  constructor(readonly blob: Blob, readonly name: string, readonly size: number) {
  }
}

export interface BlobType {

  getMimeType(): string;

  getBlobId(): string;
}

export function DisplayName(firstname?: string, lastname?: string) {
  let displayName = firstname?.length > 0 ? firstname : "";
  displayName += lastname?.length > 0 ? ((displayName.length > 0 ? " " : "") + lastname) : "";
  return displayName;
}

export function UserDisplayName(user?: User) {
  return DisplayName(user?.firstname, user?.lastname);
}

export function UserProfilePhoto(user?: User, defaultImage?: string) {
  return user?.profilePhoto || defaultImage || BaseApp.CONTEXT.getAppConfig().defaultUserImage;
}

export function UnknownUser(uid: string): User {
  return {
    uid: uid,
    firstname: "[ Unknown ]",
    lastname: "",
  } as User;
}

export class UserCache {

  private static readonly instance = new UserCache();

  private cache: Map<string, User> = new Map<string, User>();

  static getInstance(): UserCache {
    return this.instance;
  }

  setUser(uid: string, user: User): void {
    this.cache.set(uid, user);
  }

  getCachedUser(uid: string): User {
    return this.cache.get(uid);
  }

  async getUser(uid: string): Promise<User> {
    let cached = this.cache.get(uid);
    if (cached) {
      return Promise.resolve(cached);
    }
    const userRef = dbRef("users/" + uid);
    const result = await get(userRef);
    if (result.exists()) {
      const cached = JSON_OBJECT.deserializeObject(result.val(), User);
      this.cache.set(uid, cached);
      return Promise.resolve(cached);
    }
    return Promise.resolve(UnknownUser(uid));
  }

  async loadUsers(): Promise<User[]> {
    const usersRef = dbRef("users");
    const result = await get(usersRef);
    if (result.exists()) {
      const users: User[] = [];
      let val = result.val();
      for (const key in val) {
        let value = val[key];
        const user = JSON_OBJECT.deserializeObject(value, User);
        users.push(user);
      }
      return Promise.resolve(users);
    }
    return Promise.resolve([]);
  }
}

export enum TabOptionItemType {
  BUTTON = "button",
  TOGGLE = "toggle",
}

export class TabOptionItem {

  type: TabOptionItemType = TabOptionItemType.BUTTON;

  private selected: boolean = false;

  constructor(readonly id: string, readonly text: string, readonly onSelectionChanged?: (item: TabOptionItem, selected: boolean) => void, readonly onClicked?: (item: TabOptionItem) => boolean) {
  }

  isSelected(): boolean {
    return this.selected;
  }

  setSelected(selected: boolean) {
    this.selected = selected;
    this.onSelectionChanged?.(this, selected);
  }
}

export class TabOptionItemGroup {

  constructor(readonly items: TabOptionItem[], private selectedIndex: number = 0, readonly layout: "expand" | "collapse" = "expand") {
    this.setSelectedIndex(selectedIndex);
  }

  getSelectedItem(): TabOptionItem {
    return this.items[this.selectedIndex];
  }

  setSelectedIndex(selectedIndex: number) {
    this.selectedIndex = selectedIndex;
    this.items.map((item, index) => item.setSelected(selectedIndex === index));
  }
}

export class TabOptions {

  alwaysShow?: boolean;

  constructor(readonly options: (TabOptionItem | TabOptionItemGroup)[], readonly onTabSelected?: (tab: string) => void, readonly onTabClosed?: (tab: string) => boolean) {
  }
}

export class ButtonState {

  constructor(readonly disabled?: boolean, readonly selected?: boolean) {
  }

  toggleSelected(): ButtonState {
    return new ButtonState(this.disabled, !this.selected);
  }
}

export type CreateActions = (event, ...args: any[]) => Action[];

export enum ActionType {
  SEPARATOR = "separator",
  BUTTON = "button",
  GROUP = "group",
  SELECT = "select",
  POPOVER = "popover",
}

export class ActionBase {
  readonly type: ActionType;

  isSelectedFn?: () => boolean;
  isDisabledFn?: () => boolean;

  constructor(type: ActionType) {
    this.type = type;
  }

  setIsSelectedFn(isSelectedFn: () => boolean) {
    this.isSelectedFn = isSelectedFn;
    return this;
  }

  setIsDisabledFn(isDisabledFn: () => boolean) {
    this.isDisabledFn = isDisabledFn;
    return this;
  }
}

export class Action extends ActionBase {

  tag?: any;
  selected?: boolean;
  destructive?: boolean;
  secondary?: boolean;
  variant?: "text" | "contained" | "outlined";
  orientation?: "horizontal" | "vertical";
  iconFlipVertical?: boolean;
  customIconRenderer?: () => ReactElement;

  constructor(readonly text: string, readonly onClick?: (event?: any, ...args: any[]) => void, readonly iconType?: typeof SvgIcon, readonly iconUrl?: string, readonly iconify?: string, readonly disabled?: boolean) {
    super(ActionType.BUTTON);
  }

  setOrientation(orientation: "horizontal" | "vertical") {
    this.orientation = orientation;
    return this;
  }

  setIconFlipVertical() {
    this.iconFlipVertical = true;
    return this;
  }

  setCustomIconRenderer(renderer: () => ReactElement) {
    this.customIconRenderer = renderer;
    return this;
  }

  setVariant(variant: "text" | "contained" | "outlined"): Action {
    this.variant = variant;
    return this;
  }

  makeSecondary(): Action {
    this.secondary = true;
    return this;
  }

  makeDestructive(): Action {
    this.destructive = true;
    return this;
  }
}

export class ActionTool extends Action {

  constructor(text: string, onClick?: (event?: any, ...args: any[]) => void, iconType?: typeof SvgIcon, iconUrl?: string, iconify?: string, disabled?: boolean) {
    super(text, onClick, iconType, iconUrl, iconify, disabled);
    this.setOrientation("vertical");
  }
}

class ActionSeparator extends ActionBase {
  constructor() {
    super(ActionType.SEPARATOR);
  }
}

export const ACTION_SEPARATOR = new ActionSeparator();

export enum ActionGroupMode {
  NO_SELECT,
  SINGLE_SELECT,
  SINGLE_SELECT_AUTO, // Auto select the first option.
  MULTISELECT,
}

export class ActionGroup extends ActionBase {

  constructor(readonly actions: ActionBase[], readonly label?: string, readonly mode?: ActionGroupMode) {
    super(ActionType.GROUP);
    if (mode === ActionGroupMode.SINGLE_SELECT_AUTO) {
      this.filterButtons()[0].selected = true;
    }
  }

  filterButtons(): Action[] {
    return this.actions.filter(base => base.type === ActionType.BUTTON).map(base => base as Action);
  }

  onClickOverride(): (action: Action, event) => void | null {
    if (this.mode === ActionGroupMode.SINGLE_SELECT || this.mode === ActionGroupMode.SINGLE_SELECT_AUTO) {
      return (action: Action, event) => {
        this.filterButtons().forEach(button => button.selected = false);
        action.selected = true;
        action.onClick?.();
      };
    }
    if (this.mode === ActionGroupMode.MULTISELECT) {
      return (action: Action, event) => {
        action.selected = !action.selected;
        action.onClick?.();
      };
    }
    return null;
  }
}

export class ActionSelect extends ActionBase {

  constructor(readonly actions: ActionBase[], readonly text: string, readonly iconType?: typeof SvgIcon) {
    super(ActionType.SELECT);
  }

  filterButtons(): Action[] {
    return this.actions.filter(base => base.type === ActionType.BUTTON).map(base => base as Action);
  }
}

export class ActionPopover extends ActionBase {

  constructor(readonly render: () => ReactElement, readonly text: string, readonly iconType?: typeof SvgIcon) {
    super(ActionType.POPOVER);
  }

  protected hidePopover() {
    BaseApp.CONTEXT.hidePopover();
  }
}

export type EmptyConfig = {
  iconType?: typeof SvgIcon,
  iconify?: string,
  title: string,
  text?: string,
  action?: Action,
  altAction?: Action,
}

@JsonObject()
export class FirebaseOverlay {

  @JsonProperty()
  apiKey?: string;
  @JsonProperty()
  authDomain?: string;
  @JsonProperty()
  databaseURL?: string;
  @JsonProperty()
  projectId?: string;
  @JsonProperty()
  storageBucket?: string;
  @JsonProperty()
  messagingSenderId?: string;
  @JsonProperty()
  appId?: string;
  @JsonProperty()
  measurementId?: string;
}

@JsonObject()
export class AppOverlay {

  @JsonProperty()
  name?: string;
  @JsonProperty()
  icon?: string;
  @JsonProperty()
  logo?: string;
  @JsonProperty()
  stamp?: string;
  @JsonProperty()
  themeOptions?: any;
  @JsonProperty()
  defaultUserImage?: string;
  @JsonProperty()
  firebase?: FirebaseOverlay;
}

@JsonObject()
export class LoginCredentials {

  @JsonProperty()
  email: string;
  @JsonProperty()
  password: string;
}

@JsonObject()
export class AppletConfig {

  @JsonProperty()
  autoLogin?: LoginCredentials;

  @JsonProperty()
  membersKey?: MembersKey;
}

export interface AppletContext extends Context {

  getAppletId(): string;

  getAppletConfig(): AppletConfig;
}

@JsonObject()
export class ProvisionedAppConfig {

  @JsonProperty()
  provisioningId: string;
}

export interface ProvisionedAppContext extends Context {

  getProvisionedAppId(): string;

  getProvisionedAppConfig(): ProvisionedAppConfig;
}

@JsonObject()
export class PluginConfig {

  @JsonProperty()
  app?: AppOverlay;

  @JsonProperty()
  autoLogin?: LoginCredentials;

  @JsonProperty()
  membersKey?: MembersKey;
}

export interface PluginContext extends Context {

  getPluginId(): string;

  getPluginConfig(): PluginConfig;
}

export function PluginIconUrl(manifest: PluginManifest) {
  return manifest.iconUrl ? manifest.pluginUrl + "/" + manifest.iconUrl : null;
}

export function PluginIconType(manifest: PluginManifest) {
  return findIcon(manifest.iconType);
}

@JsonObject()
export class PluginManifestGroup {

  @JsonProperty()
  name: string;

  @JsonProperty()
  description: string;

  @JsonProperty({name: "icon_type"})
  iconType: string;
}

@JsonObject()
export class PluginManifest {

  // Not serialized
  pluginUrl: string;

  @JsonProperty({name: "plugin_id"})
  pluginId: string;

  @JsonProperty({name: "plugin_type"})
  pluginType?: "app" | "patient" | "case" | string;

  // Only set when pluginType === "case"
  @JsonProperty({name: "case_type"})
  caseType?: string;

  @JsonProperty()
  name: string;

  @JsonProperty()
  description: string;

  @JsonProperty({name: "icon_url"})
  iconUrl: string;

  @JsonProperty({name: "icon_type"})
  iconType: string;

  @JsonProperty({name: "website_url"})
  websiteUrl?: string;

  @JsonProperty({name: "support_email"})
  supportEmail?: string;

  @JsonProperty({name: "support_url"})
  supportUrl?: string;

  @JsonProperty({name: "privacy_policy_url"})
  privacyPolicyUrl?: string;

  @JsonProperty({name: "plugin_group"})
  pluginGroup: PluginManifestGroup;
}
