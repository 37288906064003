import {md5} from "@/shared-site/md5";
import {SiteConfig} from "@/shared-site/types";
import * as process from "process";

export const CMSADMIN_FIREBASE_CONFIG = {
  apiKey: "AIzaSyCW9C2DldUmqckgO1JRqilUJ7I64YI4MXU",
  authDomain: "fyneapps-shared.firebaseapp.com",
  databaseURL: "https://fyneapps-shared-default-rtdb.firebaseio.com",
  projectId: "fyneapps-shared",
  storageBucket: "fyneapps-shared.appspot.com",
  messagingSenderId: "1008652909857",
  appId: "1:1008652909857:web:0d00dd4cdea364cc619260",
  measurementId: "G-0DM2XPEDBB"
};

const OVERRIDE_PROVISIONING_CODE = undefined;

export function createSiteConfig(): SiteConfig {
  return {
    provisioningId: md5(process.env?.REACT_APP_PROVISIONING_CODE || OVERRIDE_PROVISIONING_CODE || "FYNEAPPSDEMO"),
    provisionedAppId: "cmsblog-admin",
    firebaseConfig: CMSADMIN_FIREBASE_CONFIG,
  }
}
