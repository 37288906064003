import {PageFragment, PageFragmentProps, PageFragmentState} from "@/shared/PageFragment";
import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "@/shared/PageWithSidebarContainer";
import React from "react";
import {Sidebar, SidebarItem, SidebarListener} from "@/shared/Sidebar";
import {SiteConfigProvider, SiteSettings, SiteSettingsObject} from "@/shared-site/types";
import {StyledContainer} from "@/shared/StyledComponents";
import {FormGenContainer} from "@/shared/FormGenContainer";
import {BaseSiteSettingsObject} from "@/cmsshared-admin/settings/types";
import {BaseApp} from "@/shared/BaseApp";

type SettingsObjectFragmentProps = PageFragmentProps & {
  sidebarItems: SidebarItem[],
  selectedSettingsObjectId: string,
};

class SettingsObjectFragment extends PageFragment<SettingsObjectFragmentProps> {

  private getSelectedItem() {
    return this.props.sidebarItems.find(item => item.object.id === this.props.selectedSettingsObjectId);
  }

  protected onCreateState(): PageFragmentState {
    return {
      ...super.onCreateState(),
      title: this.getSelectedItem().title,
    };
  }

  componentDidUpdate(prevProps: Readonly<SettingsObjectFragmentProps>, prevState: Readonly<PageFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevProps.selectedSettingsObjectId !== this.props.selectedSettingsObjectId) {
      this.setState({
        title: this.getSelectedItem().title,
      });
    }
  }

  protected getActionButtonText(): string {
    return "Save";
  }

  onActionButtonClicked(event?) {
    const selectedItem = this.getSelectedItem();
    (selectedItem.object as BaseSiteSettingsObject).save()
      .then(() => BaseApp.CONTEXT.showToast("Saved."));
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG;
  }

  renderContent(): React.ReactElement {
    const selectedItem = this.getSelectedItem();
    return <StyledContainer>
      <FormGenContainer content={selectedItem.object}/>
    </StyledContainer>;
  }
}

export type SettingsFragmentProps = PageFragmentProps & {}

type SettingsFragmentState = PageFragmentState & {
  siteSettings: SiteSettings,
  sidebarItems: SidebarItem[],
  selectedSettingsObjectId: string,
}

export class SettingsFragment extends PageFragment<SettingsFragmentProps, SettingsFragmentState> implements PageWithSidebarContainerRenderer, SidebarListener {

  private static sidebarItemType(sidebarItem: SidebarItem) {
    return (sidebarItem.object as SiteSettingsObject).id;
  }

  private getSelectedSidebarItem(): SidebarItem {
    return this.state.sidebarItems?.find(item => SettingsFragment.sidebarItemType(item) === this.state.selectedSettingsObjectId);
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const siteConfig = SiteConfigProvider.getInstance().get();
    const siteSettings = siteConfig.siteSettings;
    const sidebarItems: SidebarItem[] = [];
    for (const item of siteSettings.items) {
      await (item.object as BaseSiteSettingsObject).load();
      sidebarItems.push({
        object: item.object,
        title: item.title,
        iconType: item.iconType,
      })
    }
    this.setState({
      siteSettings: siteSettings,
      sidebarItems: sidebarItems,
      selectedSettingsObjectId: sidebarItems[0].object.id,
    })
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({selectedSettingsObjectId: item.object.id});
  }

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer renderer={this}/>
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    return <SettingsObjectFragment
      sidebarItems={this.state.sidebarItems}
      selectedSettingsObjectId={this.state.selectedSettingsObjectId}/>;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    return <Sidebar tabs={[{
      id: "default",
      selected: this.getSelectedSidebarItem(),
      items: {
        groups: [
          {
            items: this.state.sidebarItems,
            title: "Settings",
            variant: "condensed",
          },
        ],
        containerId: "settings",
      },
      listener: this,
    }]}/>;
  }
}